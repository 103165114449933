<template>

<div class="sub_components">
   <!-- face style cs-1-->
  <div v-for="index in 15" :key="index" @click="showFaceSkinStyle('#faceskin_style_cs1_female_' + index)" v-if="sex === 'female' && cs === 1">
    <img :src="require('../../assets/img/options/components/face/cs-1/female-face-' + index + '.svg')">
  </div>

  <div v-for="index in 15" :key="index" @click="showFaceSkinStyle('#faceskin_style_cs1_male_' + index)"  v-if="sex === 'male' && cs === 1">
    <img :src="require('../../assets/img/options/components/face/cs-1/male-face-' +index + '.svg')">
  </div>

  <!-- face style cs-2-->
  <div v-for="index in 10" :key="index" @click="showFaceSkinStyle('#faceskin_style_cs2_female_' + index)" v-if="sex === 'female' && cs === 2">
    <img :src="require('../../assets/img/options/components/face/cs-2/female-face-' + index + '.svg')">
  </div>

  <div v-for="index in 10" :key="index" @click="showFaceSkinStyle('#faceskin_style_cs2_male_' + index)"  v-if="sex === 'male' && cs === 2">
    <img :src="require('../../assets/img/options/components/face/cs-2/male-face-' +index + '.svg')">
  </div>
  
  <!-- face style cs-3-->
  <div v-for="index in 15" :key="index" @click="showFaceSkinStyle('#faceskin_style_cs3_female_' + index)" v-if="sex === 'female' && cs === 3">
    <img :src="require('../../assets/img/options/components/face/cs-3/female-face-' + index + '.svg')">
  </div>

  <div v-for="index in 15" :key="index" @click="showFaceSkinStyle('#faceskin_style_cs3_male_' + index)"  v-if="sex === 'male' && cs === 3">
    <img :src="require('../../assets/img/options/components/face/cs-3/male-face-' +index + '.svg')">
  </div>
  
  <!-- face style cs-4-->
  <div v-for="index in 15" :key="index" @click="showFaceSkinStyle('#faceskin_style_cs4_female_' + index)" v-if="sex === 'female' && cs === 4">
    <img :src="require('../../assets/img/options/components/face/cs-4/female-face-' + index + '.svg')">
  </div>

  <div v-for="index in 15" :key="index" @click="showFaceSkinStyle('#faceskin_style_cs4_male_' + index)"  v-if="sex === 'male' && cs === 4">
    <img :src="require('../../assets/img/options/components/face/cs-4/male-face-' +index + '.svg')">
  </div>
</div>

</template>

<script>
export default {
  name: "OptionsFaceskin",
  computed: {
    sex () {
      return this.$store.state.sex
    },
    cs () {
      return this.$store.state.cs
    }
  },
  methods: {
    deleteClass() {
      let c = document.getElementById('faceskin').children, i;
      for (i = 0; i < c.length; i++) {
        c[i].classList.remove('show');
      }
      this.$store.state.selectDom = ''
    },
    showFaceSkinStyle(id) {
      // if(document.querySelector(id).classList.contains('show')) {
      //   this.deleteClass();
      // } else {
      //   this.deleteClass();
      //   document.querySelector(id).classList.add('show');
      //   this.$store.state.selectDom = id
      // }
      this.deleteClass()
      document.querySelector(id).classList.add('show');
      this.$store.state.selectDom = id
      if (this.$store.state.cs === 1) { // 系列一身体与脸部绑定
        this.$root.$refs.SkinColor.picker()
      }
    }
  }
}
</script>

