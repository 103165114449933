<template>
  <svg id="eyeball" width="380px" height="380px" viewBox="0 0 380 380">
    <g id="eball_style_1">
        
    </g>
    <g id="eyeball_none"></g>
  </svg>
</template>


<script>
export default {
  name: "Eyeball",
  data() {
    return {
      list: []
    }
  },

  methods: {
    picker(option = false){
      this.hide_all();
      option
        ? document.querySelector(option).classList.add('show')
        : document.querySelector(this.random_option()).classList.add('show')
    },
    random_option(){
      var chosenEyes = Math.floor(Math.random() * this.list.length);
      return this.list[chosenEyes];
    },
    hide_all(){
      for (let i = 0; i < this.list.length; i++){
        document.querySelector(this.list[i]).classList.remove('show');
      }
    }
  },

  created() {
    this.$root.$refs.Eyeball = this;
  }
}
</script>

<style>
  @import url('../../assets/css/custom.css');
</style>