<template>
  <div style="margin: 20px auto 10px">
    <el-dropdown :disabled="loading" @command="saveImage" >
      <el-button type="success" round
        >Download
        <i v-if="loading" class="el-icon-loading"></i>
        <i v-else class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="png">PNG</el-dropdown-item>
        <el-dropdown-item command="svg">SVG</el-dropdown-item>
    </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import Canvg from 'canvg'
import html2canvas from 'html2canvas'
export default {
  name: 'SaveImage',
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    /**
     * This method creates an invisible anchor element which is automatically clicked
     * in order to download the image referenced by the URL passed to the method,
     *
     * @param {string} imageURL URL to the image to downloadя
     */
    downloadImage(imageURL, type) {
      // Creating an invisible anchor element and executing the 'click'
      // method seems to be the standard way of starting a download
      const downloadLink = document.createElement('a')
      downloadLink.href = imageURL
      downloadLink.download = 'avatar.' + type
      downloadLink.click()
      this.loading = false
    },
    /**
     * This method collects all _selected_ svg elements from the avatar
     * and renders them into a canvas using canvg.
     * Afterwards the image is provided as download to the user.
     */
    async saveImageOld() {
      // We need to have a single svg element which is passed to canvg
      let combinedSvg =
        '<div id="avatar" style="position:relative;width:100%;height:100%;"><svg width="380px" height="380px" viewBox="0 0 380 380" style="position: absolute;width: 100%;height: 100%;">'

      // Helper method to add only those group elements which actually exist
      // to the combined SVG string
      const addIfAvailable = (element) => {
        if (element !== undefined && element !== null) {
          combinedSvg = combinedSvg + element.outerHTML
        }
      }

      // Select the visible group '<g>' from every option in the avatar
      // and add it to the combined SVG string.
      // Note: It would be shorter to use `avatarDiv.querySelectorAll('svg')` and iterate
      //       over these entries and their '<g>' groups. This does not work here though
      //       as we need to pay attention to the order of the elements to make sure nothing
      //       is hidden by another element.
      const avatarDiv = document.querySelector('#avatar')
      addIfAvailable(avatarDiv.querySelector('#others').querySelector('.show'))
      addIfAvailable(avatarDiv.querySelector('#ear').querySelector('.show'))
      addIfAvailable(
        avatarDiv.querySelector('#skinColor').querySelector('.show')
      )
      addIfAvailable(
        avatarDiv.querySelector('#faceskin').querySelector('.show')
      )

      //addIfAvailable(avatarDiv.querySelector('#tattoos').querySelector('.show'));
      //addIfAvailable(avatarDiv.querySelector('#accesories').querySelector('.show'));
      addIfAvailable(
        avatarDiv.querySelector('#eyeball').querySelector('.show')
      )
      addIfAvailable(avatarDiv.querySelector('#noses').querySelector('.show'))
      addIfAvailable(avatarDiv.querySelector('#mouths').querySelector('.show'))
      addIfAvailable(
        avatarDiv.querySelector('#eyebrows').querySelector('.show')
      )
      console.log(
        avatarDiv.querySelector('#eyebrows').querySelector('.show'),
        11
      )
      addIfAvailable(avatarDiv.querySelector('#eyes').querySelector('.show'))
      console.log(avatarDiv.querySelector('#eyes').querySelector('.show'), 22)
      addIfAvailable(avatarDiv.querySelector('#hair').querySelector('.show'))
      addIfAvailable(
        avatarDiv.querySelector('#clothes').querySelector('.show')
      )
      addIfAvailable(
        avatarDiv.querySelector('#facialhair2').querySelector('.show')
      )
      // addIfAvailable(avatarDiv.querySelector('#glasses').querySelector('.show'));
      combinedSvg = combinedSvg + '</svg></div>'

      // Create an invisible canvas and render the combined SVG onto the canvas.
      const canvas = document.createElement('canvas')
      canvas.width = 1200
      canvas.height = 1200
      const ctx = canvas.getContext('2d')
      const drawn = Canvg.fromString(ctx, combinedSvg)

      await drawn.render()

      this.downloadImage(canvas.toDataURL('image/png'))
    },
    saveImage2() {
      this.loading = true
      setTimeout(() => {
        // 手动创建一个 canvas 标签
        const canvas = document.createElement('canvas')
        // 获取父标签，意思是这个标签内的 DOM 元素生成图片
        let canvasBox = document.querySelector('#avatar')
        // 获取父级的宽高
        const width = parseInt(window.getComputedStyle(canvasBox).width)
        const height = parseInt(window.getComputedStyle(canvasBox).height)
        // 屏幕的设备像素比
        const devicePixelRatio = window.devicePixelRatio || 1
        canvas.width = width * devicePixelRatio
        canvas.height = height * devicePixelRatio
        canvas.style.width = width + 'px'
        canvas.style.height = height + 'px'
        // const context = canvas.getContext("2d");
        // context.scale(2, 2);
        const options = {
          backgroundColor: null,
          canvas: canvas,
          useCORS: true
        }
        html2canvas(canvasBox, options).then((canvas) => {
          // toDataURL 图片格式转成 base64
          const dataURL = canvas.toDataURL('image/png')
          this.downloadImage(dataURL)
        })
      }, 200)
    },
    saveImage(command) {
      this.loading = true
      const cs = this.$store.state.cs
      const sex = this.$store.state.sex
      let cls = ''
      let clipPathR = ''
      let clipPathL = ''
      if (cs > 1) {
        // 获取选中eye的序列号
        const chooseEyeId = document.querySelector('svg#eyes> .show').id
        const selectIndex = chooseEyeId.substring(chooseEyeId.length - 1)
        cls = `.cls-path-cs${cs}-${sex}-${selectIndex}R{clip-path:url(#clip-path-cs${cs}-${sex}-${selectIndex}R)}.cls-path-cs${cs}-${sex}-${selectIndex}L{clip-path:url(#clip-path-cs${cs}-${sex}-${selectIndex}L)}`
        clipPathR = document.querySelector(`#clip-path-cs${cs}-${sex}-${selectIndex}R`) ? document.querySelector(`#clip-path-cs${cs}-${sex}-${selectIndex}R`).outerHTML : ''
        clipPathL = document.querySelector(`#clip-path-cs${cs}-${sex}-${selectIndex}L`) ? document.querySelector(`#clip-path-cs${cs}-${sex}-${selectIndex}L`).outerHTML : ''
      }
      const divContent = document.querySelector('#avatar').querySelectorAll('svg>g.show')
      let svgData = `<svg xmlns='http://www.w3.org/2000/svg' width='400' height='400'>
      <defs><style>${cls}.cls-multipy{mix-blend-mode: multiply;}.cls-softlight{mix-blend-mode: soft-light;}.cls-overlay{mix-blend-mode: overlay;}.cls-colorburn{mix-blend-mode: color-burn;}.cls-screen{mix-blend-mode: screen;}.cls-lighten{ mix-blend-mode: darken;}</style>
      ${clipPathR}${clipPathL}</defs>`
      for (let i = 0; i < divContent.length; i++) {
        svgData += divContent[i].innerHTML
      }
      svgData += '</svg>'
      const svgBlob = new Blob([svgData], {
        type: 'image/svg+xml;charset=utf-8'
      })
      const svgUrl = URL.createObjectURL(svgBlob)
      if (command === 'png') {
        const img = new Image()
        img.src = svgUrl
        img.onload = () => {
          const canvas = document.createElement('canvas')
          const ratio = window.devicePixelRatio || 1
          canvas.width = img.width * ratio
          canvas.height = img.height * ratio
          canvas.style.width = img.width + 'px'
          canvas.style.height = img.height + 'px'
          const context = canvas.getContext('2d')
          context.setTransform(ratio, 0, 0, ratio, 0, 0)
          context.drawImage(img, 0, 0)
          const ImgBase64 = canvas.toDataURL('image/png')
          this.downloadImage(ImgBase64, 'png')
        }
      } else {
        this.downloadImage(svgUrl, 'svg')
      }
    }
  }
}
</script>
