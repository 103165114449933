<template>

<div class="sub_components">
   <!-- mouth style cs-1-->
  <div v-for="index in 15" :key="index" @click="showMouths('#m_female_style_cs1_' + index)" v-if="sex === 'female' && cs === 1">
    <img :src="require('../../assets/img/options/components/mouth/cs-1/female-mouth-' + index + '.svg')">
  </div>

  <div v-for="index in 15" :key="index" @click="showMouths('#m_male_style_cs1_' + index)"  v-if="sex === 'male' && cs === 1">
    <img :src="require('../../assets/img/options/components/mouth/cs-1/male-mouth-' +index + '.svg')">
  </div>

  <!-- mouth style cs-2-->
  <div v-for="index in 10" :key="index" @click="showMouths('#m_female_style_cs2_' + index)" v-if="sex === 'female' && cs === 2">
    <img :src="require('../../assets/img/options/components/mouth/cs-2/female-mouth-' + index + '.svg')">
  </div>

  <div v-for="index in 10" :key="index" @click="showMouths('#m_male_style_cs2_' + index)"  v-if="sex === 'male' && cs === 2">
    <img :src="require('../../assets/img/options/components/mouth/cs-2/male-mouth-' +index + '.svg')">
  </div>

  <!-- mouth style cs-3-->
  <div v-for="index in 15" :key="index" @click="showMouths('#m_female_style_cs3_' + index)" v-if="sex === 'female' && cs === 3">
    <img :src="require('../../assets/img/options/components/mouth/cs-3/female-mouth-' + index + '.svg')">
  </div>

  <div v-for="index in 15" :key="index" @click="showMouths('#m_male_style_cs3_' + index)"  v-if="sex === 'male' && cs === 3">
    <img :src="require('../../assets/img/options/components/mouth/cs-3/male-mouth-' +index + '.svg')">
  </div>

  <!-- mouth style cs-4-->
  <div v-for="index in 15" :key="index" @click="showMouths('#m_female_style_cs4_' + index)" v-if="sex === 'female' && cs === 4">
    <img :src="require('../../assets/img/options/components/mouth/cs-4/female-mouth-' + index + '.svg')">
  </div>

  <div v-for="index in 15" :key="index" @click="showMouths('#m_male_style_cs4_' + index)"  v-if="sex === 'male' && cs === 4">
    <img :src="require('../../assets/img/options/components/mouth/cs-4/male-mouth-' +index + '.svg')">
  </div>
</div>

</template>

<script>
export default {
  name: "OptionsMouths",
  computed: {
    sex () {
      return this.$store.state.sex
    },
    cs () {
      return this.$store.state.cs
    }
  },
  methods: {
    deleteClass() {
      let c = document.getElementById('mouths').children, i;
      for (i = 0; i < c.length; i++) {
        c[i].classList.remove('show');
      }
      this.$store.state.selectDom = ''
    },
     showMouths(id) {
      this.deleteClass();
      document.querySelector(id).classList.add('show');
      this.$store.state.selectDom = id
    }
  }
}
</script>


