<template>

<div id="OptionSkinColor">

  <el-color-picker v-model="chooseColor" class="elColorPick" :disabled="!selectDom" @active-change="changeColor" @change="sureColor" :show-alpha="false"></el-color-picker>

</div>

</template>

<script>
export default {
  name: "OptionSkinColor",
  data() {
    return {
      chooseColor: ''
    }
  },
  computed: {
    selectDom () {
      return this.$store.state.selectDom
    },
    selectDom2 () {
      return this.$store.state.selectDom2
    }
  },
  methods: {
    changeColor (val) {
      let c = document.querySelector(this.selectDom).getElementsByClassName('canChange'), i
      for (i = 0; i < c.length; i++) {
        c[i].setAttribute("style", "fill:" + val)
      }
      if (this.selectDom2) this.changeColor2(val)
      this.changeBody(val)
    },
    changeColor2 (val) {
      let c = document.querySelector(this.selectDom2).getElementsByClassName('canChange'), i
      for (i = 0; i < c.length; i++) {
        c[i].setAttribute("style", "fill:" + val)
      }
    },
    changeColorCustom (val, domId) {
      let c = document.querySelector(domId).getElementsByClassName('canChange'), i
      for (i = 0; i < c.length; i++) {
        c[i].setAttribute("style", "fill:" + val)
      }
    },
    sureColor () {
      const g = document.querySelector(this.selectDom)
      g.setAttribute("style", "fill:" + this.chooseColor)
      let c = document.querySelector(this.selectDom).getElementsByClassName('canChange'), i;
      for (i = 0; i < c.length; i++) {
        c[i].setAttribute("style", "fill:" + this.chooseColor)
      }
      if (this.selectDom2) this.changeColor2(this.chooseColor)
      this.changeBody(this.chooseColor)
    },
    changeBody(val) {
      if (this.selectDom.includes('faceskin')) {
        if (this.selectDom.includes('cs1')) {
          const arr = this.selectDom.split('_')
          const domId = `#body_style_cs1_${this.$store.state.sex}_${arr[arr.length-1]}`
          this.changeColorCustom(val, domId)
        } else if (this.selectDom.includes('cs2')) {
          this.changeColorCustom(val, '#skin_white_cs2_' + this.$store.state.sex)
        }
      }
    }
  }
}
</script>