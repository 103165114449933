import Vue from 'vue';
import App from './App.vue';
import VueMeta from 'vue-meta';
// import 'element-ui/lib/theme-chalk/index.css';
// import { Button,ColorPicker } from 'element-ui'
// Vue.use(Button,ColorPicker)
import ElementUI from 'element-ui' //element-ui的全部组件
import 'element-ui/lib/theme-chalk/index.css'//element-ui的css
import store from './store'
Vue.use(ElementUI) //使用elementUI

Vue.config.productionTip = false;

Vue.use(VueMeta);

new Vue({
  el: '#app',
  store,
  render: h => h(App),
});