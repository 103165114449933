<template>

<div class="sub_components">
  <!-- <div @click="showNoses('#noses_style_1')" v-if="cs === 1">
    <img :src="require('../../assets/img/options/components/nose/cs-1/nose-1.svg')">
  </div>  

  <div @click="showNoses('#noses_style_2')" v-if="cs === 1">
    <img :src="require('../../assets/img/options/components/nose/cs-1/nose-2.svg')">
  </div>  

  <div @click="showNoses('#noses_style_3')" v-if="cs === 1">
   <img :src="require('../../assets/img/options/components/nose/cs-1/nose-3.svg')">
  </div>  

  <div @click="showNoses('#noses_style_4')" v-if="cs === 1">
    <img :src="require('../../assets/img/options/components/nose/cs-1/nose-4.svg')">
  </div>  

  <div @click="showNoses('#noses_style_5')" v-if="cs === 1">
    <img :src="require('../../assets/img/options/components/nose/cs-1/nose-5.svg')">
  </div>   -->

  <!-- mouth style cs-2-->
  <div v-for="index in 10" :key="index" @click="showNoses('#noses_female_style_cs2_' + index)" v-if="sex === 'female' && cs === 2">
    <img :src="require('../../assets/img/options/components/nose/cs-2/female-nose-' + index + '.svg')">
  </div>

  <div v-for="index in 10" :key="index" @click="showNoses('#noses_male_style_cs2_' + index)"  v-if="sex === 'male' && cs === 2">
    <img :src="require('../../assets/img/options/components/nose/cs-2/male-nose-' +index + '.svg')">
  </div>

  <!-- mouth style cs-3-->
  <div v-for="index in 15" :key="index" @click="showNoses('#noses_female_style_cs3_' + index)" v-if="sex === 'female' && cs === 3">
    <img :src="require('../../assets/img/options/components/nose/cs-3/female-nose-' + index + '.svg')">
  </div>

  <div v-for="index in 15" :key="index" @click="showNoses('#noses_male_style_cs3_' + index)"  v-if="sex === 'male' && cs === 3">
    <img :src="require('../../assets/img/options/components/nose/cs-3/male-nose-' +index + '.svg')">
  </div>  

  <!-- mouth style cs-4-->
  <div v-for="index in 15" :key="index" @click="showNoses('#noses_female_style_cs4_' + index)" v-if="sex === 'female' && cs === 4">
    <img :src="require('../../assets/img/options/components/nose/cs-4/female-nose-' + index + '.svg')">
  </div>

  <div v-for="index in 15" :key="index" @click="showNoses('#noses_male_style_cs4_' + index)"  v-if="sex === 'male' && cs === 4">
    <img :src="require('../../assets/img/options/components/nose/cs-4/male-nose-' +index + '.svg')">
  </div>  
</div>

</template>

<script>
export default {
  name: "OptionsEyes",
  computed: {
    sex () {
      return this.$store.state.sex
    },
    cs () {
      return this.$store.state.cs
    }
  },
  methods: {
    deleteClass() {
      let c = document.getElementById('noses').children, i;
      for (i = 0; i < c.length; i++) {
        c[i].classList.remove('show');
      }
      this.$store.state.selectDom = ''
    },
     showNoses(id) {
      this.deleteClass();
      document.querySelector(id).classList.add('show');
      this.$store.state.selectDom = id
    }
  }
}
</script>


