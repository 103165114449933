<template>
  <div id="tool">
    <div class="settings">
      <!-- <img class="random" @click="randomAvatar()" :src="require('../assets/img/random.svg')">
      
      <img class="random" @click="clearAvatar()" :src="require('../assets/img/clear.svg')"> -->
      <OptionsColor><img height="24px" src="../assets/img/color.png"/></OptionsColor>
      <el-button type="info" circle class="btn_item" @click="changeSex('male')" :class="{'activeSex':sex === 'male'}"><img height="24px" src="../assets/img/tools/male.svg"></el-button>
      <el-button type="info" circle class="btn_item" @click="changeSex('female')" :class="{'activeSex':sex === 'female'}"><img height="24px" src="../assets/img/tools/female.svg"></el-button>
      <el-button type="info" circle class="btn_item" @click="randomAvatar()"><img height="24px" src="../assets/img/tools/random.svg"></el-button>
      <el-button type="info" circle class="btn_item" @click="clearAvatar()"><img height="24px" src="../assets/img/tools/reset.svg"></el-button>
    </div>
    <SaveImage/>
  </div>
</template>

<script>

import SaveImage from './save_image'
import OptionsColor from './optionsColor'

export default {
  name: "tool",
  components: {
    SaveImage,
    OptionsColor
  },
  computed: {
    sex () {
      return this.$store.state.sex
    }
  },
  methods: {
    randomAvatar() {
      this.$root.$refs.Options.resetMenu();
      this.$root.$refs.App.randomAvatar();
    },
    clearAvatar(){
      this.$root.$refs.Options.resetMenu();
      this.$root.$refs.App.resetAvatar();
      this.clearCustomColor()
    },
    changeSex(sex){
      this.$store.state.sex = sex
      this.$root.$refs.App.resetAvatar();
    },
    //清除组件自定义颜色
    clearCustomColor(){
      const path = document.getElementsByClassName('canChange')
      for(let i = 0; i < path.length; i++){
        path[i].setAttribute("style", "fill:null")
      }
    }
  }
}
</script>