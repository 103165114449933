<template>
  <svg id="skinColor" width="380px" height="380px" viewBox="0 0 380 380" style="z-index: 80">
    <g id="body_style_cs1_male_1"><path fill="#ffdbc7" class="canChange" stroke="#000" stroke-miterlimit="10" stroke-width="5" d="M303.13,380v19.39H96.87V380c0-38.61,19.76-71.1,45.91-78.94a42.23,42.23,0,0,1,8.94-1.67h0c1.08-.09,2.16-.14,3.26-.14h90c1.1,0,2.18,0,3.26.14h0a42.32,42.32,0,0,1,12.16,2.78C285,311.65,303.13,343,303.13,380Z"/></g>
    <g id="body_style_cs1_male_2"><path fill="#ffc09c" class="canChange" stroke="#000" stroke-miterlimit="10" stroke-width="5" d="M303.13,380v19.39H96.87V380c0-38.61,19.76-71.1,45.91-78.94a42.23,42.23,0,0,1,8.94-1.67h0c1.08-.09,2.16-.14,3.26-.14h90c1.1,0,2.18,0,3.26.14h0a42.32,42.32,0,0,1,12.16,2.78C285,311.65,303.13,343,303.13,380Z"/></g>
    <g id="body_style_cs1_male_3"><path fill="#ffa673" class="canChange" stroke="#000" stroke-miterlimit="10" stroke-width="5" d="M303.13,380v19.39H96.87V380c0-38.61,19.76-71.1,45.91-78.94a42.23,42.23,0,0,1,8.94-1.67h0c1.08-.09,2.16-.14,3.26-.14h90c1.1,0,2.18,0,3.26.14h0a42.32,42.32,0,0,1,12.16,2.78C285,311.65,303.13,343,303.13,380Z"/></g>
    <g id="body_style_cs1_male_4"><path fill="#ffc1b5" class="canChange" stroke="#000" stroke-miterlimit="10" stroke-width="5" d="M303.13,380v19.39H96.87V380c0-38.61,19.76-71.1,45.91-78.94a42.23,42.23,0,0,1,8.94-1.67h0c1.08-.09,2.16-.14,3.26-.14h90c1.1,0,2.18,0,3.26.14h0a42.32,42.32,0,0,1,12.16,2.78C285,311.65,303.13,343,303.13,380Z"/></g>
    <g id="body_style_cs1_male_5"><path fill="#ff9f8c" class="canChange" stroke="#000" stroke-miterlimit="10" stroke-width="5" d="M303.13,380v19.39H96.87V380c0-38.61,19.76-71.1,45.91-78.94a42.23,42.23,0,0,1,8.94-1.67h0c1.08-.09,2.16-.14,3.26-.14h90c1.1,0,2.18,0,3.26.14h0a42.32,42.32,0,0,1,12.16,2.78C285,311.65,303.13,343,303.13,380Z"/></g>
    <g id="body_style_cs1_male_6"><path fill="#ff795e" class="canChange" stroke="#000" stroke-miterlimit="10" stroke-width="5" d="M303.13,380v19.39H96.87V380c0-38.61,19.76-71.1,45.91-78.94a42.23,42.23,0,0,1,8.94-1.67h0c1.08-.09,2.16-.14,3.26-.14h90c1.1,0,2.18,0,3.26.14h0a42.32,42.32,0,0,1,12.16,2.78C285,311.65,303.13,343,303.13,380Z"/></g>
    <g id="body_style_cs1_male_7"><path fill="#ff995e" class="canChange" stroke="#000" stroke-miterlimit="10" stroke-width="5" d="M303.13,380v19.39H96.87V380c0-38.61,19.76-71.1,45.91-78.94a42.23,42.23,0,0,1,8.94-1.67h0c1.08-.09,2.16-.14,3.26-.14h90c1.1,0,2.18,0,3.26.14h0a42.32,42.32,0,0,1,12.16,2.78C285,311.65,303.13,343,303.13,380Z"/></g>
    <g id="body_style_cs1_male_8"><path fill="#eb8347" class="canChange" stroke="#000" stroke-miterlimit="10" stroke-width="5" d="M303.13,380v19.39H96.87V380c0-38.61,19.76-71.1,45.91-78.94a42.23,42.23,0,0,1,8.94-1.67h0c1.08-.09,2.16-.14,3.26-.14h90c1.1,0,2.18,0,3.26.14h0a42.32,42.32,0,0,1,12.16,2.78C285,311.65,303.13,343,303.13,380Z"/></g>
    <g id="body_style_cs1_male_9"><path fill="#c9703d" class="canChange" stroke="#000" stroke-miterlimit="10" stroke-width="5" d="M303.13,380v19.39H96.87V380c0-38.61,19.76-71.1,45.91-78.94a42.23,42.23,0,0,1,8.94-1.67h0c1.08-.09,2.16-.14,3.26-.14h90c1.1,0,2.18,0,3.26.14h0a42.32,42.32,0,0,1,12.16,2.78C285,311.65,303.13,343,303.13,380Z"/></g>
    <g id="body_style_cs1_male_10"><path fill="#ff8f69" class="canChange" stroke="#000" stroke-miterlimit="10" stroke-width="5" d="M303.13,380v19.39H96.87V380c0-38.61,19.76-71.1,45.91-78.94a42.23,42.23,0,0,1,8.94-1.67h0c1.08-.09,2.16-.14,3.26-.14h90c1.1,0,2.18,0,3.26.14h0a42.32,42.32,0,0,1,12.16,2.78C285,311.65,303.13,343,303.13,380Z"/></g>
    <g id="body_style_cs1_male_11"><path fill="#e67953" class="canChange" stroke="#000" stroke-miterlimit="10" stroke-width="5" d="M303.13,380v19.39H96.87V380c0-38.61,19.76-71.1,45.91-78.94a42.23,42.23,0,0,1,8.94-1.67h0c1.08-.09,2.16-.14,3.26-.14h90c1.1,0,2.18,0,3.26.14h0a42.32,42.32,0,0,1,12.16,2.78C285,311.65,303.13,343,303.13,380Z"/></g>
    <g id="body_style_cs1_male_12"><path fill="#c46747" class="canChange" stroke="#000" stroke-miterlimit="10" stroke-width="5" d="M303.13,380v19.39H96.87V380c0-38.61,19.76-71.1,45.91-78.94a42.23,42.23,0,0,1,8.94-1.67h0c1.08-.09,2.16-.14,3.26-.14h90c1.1,0,2.18,0,3.26.14h0a42.32,42.32,0,0,1,12.16,2.78C285,311.65,303.13,343,303.13,380Z"/></g>
    <g id="body_style_cs1_male_13"><path fill="#a6573c" class="canChange" stroke="#000" stroke-miterlimit="10" stroke-width="5" d="M303.13,380v19.39H96.87V380c0-38.61,19.76-71.1,45.91-78.94a42.23,42.23,0,0,1,8.94-1.67h0c1.08-.09,2.16-.14,3.26-.14h90c1.1,0,2.18,0,3.26.14h0a42.32,42.32,0,0,1,12.16,2.78C285,311.65,303.13,343,303.13,380Z"/></g>
    <g id="body_style_cs1_male_14"><path fill="#82442f" class="canChange" stroke="#000" stroke-miterlimit="10" stroke-width="5" d="M303.13,380v19.39H96.87V380c0-38.61,19.76-71.1,45.91-78.94a42.23,42.23,0,0,1,8.94-1.67h0c1.08-.09,2.16-.14,3.26-.14h90c1.1,0,2.18,0,3.26.14h0a42.32,42.32,0,0,1,12.16,2.78C285,311.65,303.13,343,303.13,380Z"/></g>
    <g id="body_style_cs1_male_15"><path fill="#5e230f" class="canChange" stroke="#000" stroke-miterlimit="10" stroke-width="5" d="M303.13,380v19.39H96.87V380c0-38.61,19.76-71.1,45.91-78.94a42.23,42.23,0,0,1,8.94-1.67h0c1.08-.09,2.16-.14,3.26-.14h90c1.1,0,2.18,0,3.26.14h0a42.32,42.32,0,0,1,12.16,2.78C285,311.65,303.13,343,303.13,380Z"/></g>

    <g id="body_style_cs1_female_1"><path fill="#ffdbc7" class="canChange" stroke="#000" stroke-miterlimit="10" stroke-width="5" d="M294.07,380v19.39H105.93V380c0-38.61,18-71.1,41.88-78.94a35.64,35.64,0,0,1,8.16-1.67h0c1-.09,2-.14,3-.14h82.1c1,0,2,0,3,.14h0a35.87,35.87,0,0,1,11.1,2.78C277.51,311.65,294.07,343,294.07,380Z"/></g>
    <g id="body_style_cs1_female_2"><path fill="#ffc09c" class="canChange" stroke="#000" stroke-miterlimit="10" stroke-width="5" d="M294.07,380v19.39H105.93V380c0-38.61,18-71.1,41.88-78.94a35.64,35.64,0,0,1,8.16-1.67h0c1-.09,2-.14,3-.14h82.1c1,0,2,0,3,.14h0a35.87,35.87,0,0,1,11.1,2.78C277.51,311.65,294.07,343,294.07,380Z"/></g>
    <g id="body_style_cs1_female_3"><path fill="#2e0f0e" class="canChange" stroke="#000" stroke-miterlimit="10" stroke-width="5" d="M294.07,380v19.39H105.93V380c0-38.61,18-71.1,41.88-78.94a35.64,35.64,0,0,1,8.16-1.67h0c1-.09,2-.14,3-.14h82.1c1,0,2,0,3,.14h0a35.87,35.87,0,0,1,11.1,2.78C277.51,311.65,294.07,343,294.07,380Z"/></g>
    <g id="body_style_cs1_female_4"><path fill="#ffc1b5" class="canChange" stroke="#000" stroke-miterlimit="10" stroke-width="5" d="M294.07,380v19.39H105.93V380c0-38.61,18-71.1,41.88-78.94a35.64,35.64,0,0,1,8.16-1.67h0c1-.09,2-.14,3-.14h82.1c1,0,2,0,3,.14h0a35.87,35.87,0,0,1,11.1,2.78C277.51,311.65,294.07,343,294.07,380Z"/></g>
    <g id="body_style_cs1_female_5"><path fill="#ff9f8c" class="canChange" stroke="#000" stroke-miterlimit="10" stroke-width="5" d="M294.07,380v19.39H105.93V380c0-38.61,18-71.1,41.88-78.94a35.64,35.64,0,0,1,8.16-1.67h0c1-.09,2-.14,3-.14h82.1c1,0,2,0,3,.14h0a35.87,35.87,0,0,1,11.1,2.78C277.51,311.65,294.07,343,294.07,380Z"/></g>
    <g id="body_style_cs1_female_6"><path fill="#ff795e" class="canChange" stroke="#000" stroke-miterlimit="10" stroke-width="5" d="M294.07,380v19.39H105.93V380c0-38.61,18-71.1,41.88-78.94a35.64,35.64,0,0,1,8.16-1.67h0c1-.09,2-.14,3-.14h82.1c1,0,2,0,3,.14h0a35.87,35.87,0,0,1,11.1,2.78C277.51,311.65,294.07,343,294.07,380Z"/></g>
    <g id="body_style_cs1_female_7"><path fill="#ff995e" class="canChange" stroke="#000" stroke-miterlimit="10" stroke-width="5" d="M294.07,380v19.39H105.93V380c0-38.61,18-71.1,41.88-78.94a35.64,35.64,0,0,1,8.16-1.67h0c1-.09,2-.14,3-.14h82.1c1,0,2,0,3,.14h0a35.87,35.87,0,0,1,11.1,2.78C277.51,311.65,294.07,343,294.07,380Z"/></g>
    <g id="body_style_cs1_female_8"><path fill="#eb8347" class="canChange" stroke="#000" stroke-miterlimit="10" stroke-width="5" d="M294.07,380v19.39H105.93V380c0-38.61,18-71.1,41.88-78.94a35.64,35.64,0,0,1,8.16-1.67h0c1-.09,2-.14,3-.14h82.1c1,0,2,0,3,.14h0a35.87,35.87,0,0,1,11.1,2.78C277.51,311.65,294.07,343,294.07,380Z"/></g>
    <g id="body_style_cs1_female_9"><path fill="#c9703d" class="canChange" stroke="#000" stroke-miterlimit="10" stroke-width="5" d="M294.07,380v19.39H105.93V380c0-38.61,18-71.1,41.88-78.94a35.64,35.64,0,0,1,8.16-1.67h0c1-.09,2-.14,3-.14h82.1c1,0,2,0,3,.14h0a35.87,35.87,0,0,1,11.1,2.78C277.51,311.65,294.07,343,294.07,380Z"/></g>
    <g id="body_style_cs1_female_10"><path fill="#ff8f69" class="canChange" stroke="#000" stroke-miterlimit="10" stroke-width="5" d="M294.07,380v19.39H105.93V380c0-38.61,18-71.1,41.88-78.94a35.64,35.64,0,0,1,8.16-1.67h0c1-.09,2-.14,3-.14h82.1c1,0,2,0,3,.14h0a35.87,35.87,0,0,1,11.1,2.78C277.51,311.65,294.07,343,294.07,380Z"/></g>
    <g id="body_style_cs1_female_11"><path fill="#e67953" class="canChange" stroke="#000" stroke-miterlimit="10" stroke-width="5" d="M294.07,380v19.39H105.93V380c0-38.61,18-71.1,41.88-78.94a35.64,35.64,0,0,1,8.16-1.67h0c1-.09,2-.14,3-.14h82.1c1,0,2,0,3,.14h0a35.87,35.87,0,0,1,11.1,2.78C277.51,311.65,294.07,343,294.07,380Z"/></g>
    <g id="body_style_cs1_female_12"><path fill="#c46747" class="canChange" stroke="#000" stroke-miterlimit="10" stroke-width="5" d="M294.07,380v19.39H105.93V380c0-38.61,18-71.1,41.88-78.94a35.64,35.64,0,0,1,8.16-1.67h0c1-.09,2-.14,3-.14h82.1c1,0,2,0,3,.14h0a35.87,35.87,0,0,1,11.1,2.78C277.51,311.65,294.07,343,294.07,380Z"/></g>
    <g id="body_style_cs1_female_13"><path fill="#a6573c" cclass="canChange" stroke="#000" stroke-miterlimit="10" stroke-width="5" d="M294.07,380v19.39H105.93V380c0-38.61,18-71.1,41.88-78.94a35.64,35.64,0,0,1,8.16-1.67h0c1-.09,2-.14,3-.14h82.1c1,0,2,0,3,.14h0a35.87,35.87,0,0,1,11.1,2.78C277.51,311.65,294.07,343,294.07,380Z"/></g>
    <g id="body_style_cs1_female_14"><path fill="#82442f" class="canChange" stroke="#000" stroke-miterlimit="10" stroke-width="5" d="M294.07,380v19.39H105.93V380c0-38.61,18-71.1,41.88-78.94a35.64,35.64,0,0,1,8.16-1.67h0c1-.09,2-.14,3-.14h82.1c1,0,2,0,3,.14h0a35.87,35.87,0,0,1,11.1,2.78C277.51,311.65,294.07,343,294.07,380Z"/></g>
    <g id="body_style_cs1_female_15"><path fill="#5e230f" class="canChange" stroke="#000" stroke-miterlimit="10" stroke-width="5" d="M294.07,380v19.39H105.93V380c0-38.61,18-71.1,41.88-78.94a35.64,35.64,0,0,1,8.16-1.67h0c1-.09,2-.14,3-.14h82.1c1,0,2,0,3,.14h0a35.87,35.87,0,0,1,11.1,2.78C277.51,311.65,294.07,343,294.07,380Z"/></g>
    
    <g id="skin_white">   
      <path fill="#ffac9d" d="M351.57,332c-13.44-36.1-95.61-53.35-110-63.81-18.48-13.44-12.6-57.94-12.6-57.94l-30.23.79-30.23-.79s5.88,44.5-12.59,57.94c-14.39,10.46-96.57,27.71-110,63.81a395.4,395.4,0,0,0-18.47,68H370A395.4,395.4,0,0,0,351.57,332Z"/>
      <path class="canChange" fill="#e08880" d="M162.91,259.87c14.15-1.44,28.38-4.31,40.7-11.2A139.26,139.26,0,0,0,228.18,231a137.73,137.73,0,0,1,.79-20.81l-30.23.79-30.23-.79S172.69,241.9,162.91,259.87Z"/>
    </g>
    <g id="skin_white_cs2_female">
      <path fill="#f0ba99" d="M327,400l-254-.31s3.06-26,23.7-40.14c7.39-5.05,17-8.57,29.59-8.82,47.64-1,50.74-8.12,51.24-31.05.07-3.12,0-5.91-.1-8.41-.81-15.87-4.93-19.85-4.93-19.85l55.23.06s-5.54,5.33-5.1,28.26c0,1.75.08,3.4.17,5,1,19,7,25.22,51,26.2C321.46,352,327,400,327,400Z"/>
      <path fill="#1f0905" d="M207.24,367.89a12.69,12.69,0,0,1,4-4.09A14,14,0,0,1,216.8,362a39.72,39.72,0,0,1,5.76-.2l5.73.09c3.81.12,7.62.19,11.43.26s7.62.07,11.44,0c-1.9.23-3.8.37-5.71.51s-3.82.17-5.73.22c-3.82.09-7.64,0-11.45,0l-5.73-.18a56.17,56.17,0,0,0-5.66.05,12.62,12.62,0,0,0-9.64,5.1Z"/>
      <path fill="#1f0905" d="M192.76,367.89a12.62,12.62,0,0,0-9.64-5.1,56.17,56.17,0,0,0-5.66-.05l-5.73.18c-3.81,0-7.63.12-11.45,0-1.91-.05-3.82-.12-5.73-.22s-3.81-.28-5.71-.51c3.82,0,7.63,0,11.44,0s7.62-.14,11.43-.26l5.73-.09a39.72,39.72,0,0,1,5.76.2,14,14,0,0,1,5.53,1.77A12.69,12.69,0,0,1,192.76,367.89Z"/>
      <path opacity="0.33" class="cls-overlay" d="M222.46,319.72c0,1.29.06,2.53.11,3.73l-.17.21c-13.48,16.19-36,7.1-45.3,2.33.14-1.94.21-4,.25-6.27.07-3.12,0-5.91-.11-8.41-.82-15.87-5-19.85-5-19.85h55.23S222,296.79,222.46,319.72Z"/>
    </g>
    <g id="skin_white_cs2_male">
      <path fill="#f0ba99" d="M353.72,400H46.28c6.79-15.07,12.72-28.47,14-32,3.18-9.21,94.18-27.09,102.67-29.92,5.2-1.73,7.68-22.36,8.83-38.1.72-10,.91-18,.91-18h54.7s.65,27.68,4.19,44.2c1.37,6.4,3.18,11.13,5.55,11.92,8.49,2.83,99.49,20.71,102.67,29.92C341,371.53,346.93,384.93,353.72,400Z"/>
      <path opacity="0.33" class="cls-overlay" d="M228.42,302c-28.37,17.51-48.32,8-57,1.62.11-1.23.2-2.45.29-3.65.72-10,.91-18,.91-18h54.7S227.56,291.05,228.42,302Z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "SkinColor",
  data() {
    return {
      list: [
        '#body_style_cs1_male_1',
        '#body_style_cs1_male_2',
        '#body_style_cs1_male_3',
        '#body_style_cs1_male_4',
        '#body_style_cs1_male_5',
        '#body_style_cs1_male_6',
        '#body_style_cs1_male_7',
        '#body_style_cs1_male_8',
        '#body_style_cs1_male_9',
        '#body_style_cs1_male_10',
        '#body_style_cs1_male_11',
        '#body_style_cs1_male_12',
        '#body_style_cs1_male_13',
        '#body_style_cs1_male_14',
        '#body_style_cs1_male_15',
        '#body_style_cs1_female_1',
        '#body_style_cs1_female_2',
        '#body_style_cs1_female_3',
        '#body_style_cs1_female_4',
        '#body_style_cs1_female_5',
        '#body_style_cs1_female_6',
        '#body_style_cs1_female_7',
        '#body_style_cs1_female_8',
        '#body_style_cs1_female_9',
        '#body_style_cs1_female_10',
        '#body_style_cs1_female_11',
        '#body_style_cs1_female_12',
        '#body_style_cs1_female_13',
        '#body_style_cs1_female_14',
        '#body_style_cs1_female_15',
        // '#skin_white',
        // '#skin_white_cs2_female',
        // '#skin_white_cs2_male'
      ]
    }
  },
  methods: {
    picker(option = false) {
      this.hide_all();
      if (this.$store.state.cs > 1) return
      if (this.$store.state.cs === 1 && !option) {
        const arr = document.querySelector('#faceskin .show').id.split('_')
        document.querySelector(`#body_style_cs1_${this.$store.state.sex}_${arr[arr.length-1]}`).classList.add('show')
        return
      }
      option
        ? document.querySelector(option).classList.add('show')
        : document.querySelector(this.random_option()).classList.add('show');
    },
    random_option() {
      const list = this.list.filter(item => {
        return item.includes('cs' + this.$store.state.cs) && item.includes('_' + this.$store.state.sex)
      })
      var chosen = Math.floor(Math.random() * list.length)
      return list[chosen]
    },
    hide_all() {
      for (let i = 0; i < this.list.length; i++) {
        document.querySelector(this.list[i]).classList.remove('show')
      }
    }
  },
  created() {
    this.$root.$refs.SkinColor = this;
  }
}
</script>

<style>
  @import url('../../assets/css/custom.css');
</style>