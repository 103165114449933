<template>

<div class="sub_components">

  <div @click="showHairStyle('#hair_male_style_cs1_1')" v-if="sex === 'male' && cs === 1">
   <img :src="require('../../assets/img/options/components/hair/cs-1/male-hair-1.svg')">
  </div>

  <div @click="showHairStyle('#hair_male_style_cs1_2')" v-if="sex === 'male' && cs === 1">
    <img :src="require('../../assets/img/options/components/hair/cs-1/male-hair-2.svg')">
  </div>

  <div @click="showHairStyle('#hair_male_style_cs1_3')" v-if="sex === 'male' && cs === 1">
    <img :src="require('../../assets/img/options/components/hair/cs-1/male-hair-3.svg')">
  </div>

  <div @click="showHairStyle('#hair_male_style_cs1_4')" v-if="sex === 'male' && cs === 1">
    <img :src="require('../../assets/img/options/components/hair/cs-1/male-hair-4.svg')">
  </div>

  <div @click="showHairStyle('#hair_male_style_cs1_5')" v-if="sex === 'male' && cs === 1">
    <img :src="require('../../assets/img/options/components/hair/cs-1/male-hair-5.svg')">
  </div>

  <div @click="showHairStyle('#hair_male_style_cs1_6', '#hair_male_style_6_back')" v-if="sex === 'male' && cs === 1">
    <img :src="require('../../assets/img/options/components/hair/cs-1/male-hair-6.svg')">
  </div>

  <div @click="showHairStyle('#hair_male_style_cs1_7')" v-if="sex === 'male' && cs === 1">
    <img :src="require('../../assets/img/options/components/hair/cs-1/male-hair-7.svg')">
  </div>

  <div @click="showHairStyle('#hair_male_style_cs1_8')" v-if="sex === 'male' && cs === 1">
    <img :src="require('../../assets/img/options/components/hair/cs-1/male-hair-8.svg')">
  </div>

  <div @click="showHairStyle('#hair_male_style_cs1_9')" v-if="sex === 'male' && cs === 1">
    <img :src="require('../../assets/img/options/components/hair/cs-1/male-hair-9.svg')">
  </div>

  <div @click="showHairStyle('#hair_male_style_cs1_10')" v-if="sex === 'male' && cs === 1">
    <img :src="require('../../assets/img/options/components/hair/cs-1/male-hair-10.svg')">
  </div>

  <div @click="showHairStyle('#hair_male_style_cs1_11')" v-if="sex === 'male' && cs === 1">
    <img :src="require('../../assets/img/options/components/hair/cs-1/male-hair-11.svg')">
  </div>

  <div @click="showHairStyle('#hair_male_style_cs1_12')" v-if="sex === 'male'  && cs === 1">
    <img :src="require('../../assets/img/options/components/hair/cs-1/male-hair-12.svg')">
  </div>

  <div @click="showHairStyle('#hair_male_style_cs1_13')" v-if="sex === 'male'  && cs === 1">
    <img :src="require('../../assets/img/options/components/hair/cs-1/male-hair-13.svg')">
  </div>

  <div @click="showHairStyle('#hair_male_style_cs1_14')" v-if="sex === 'male'  && cs === 1">
    <img :src="require('../../assets/img/options/components/hair/cs-1/male-hair-14.svg')">
  </div>

  <div @click="showHairStyle('#hair_male_style_cs1_15')" v-if="sex === 'male'  && cs === 1">
    <img :src="require('../../assets/img/options/components/hair/cs-1/male-hair-15.svg')">
  </div>


  <div @click="showHairStyle('#hair_female_style_cs1_1', '#hair_female_style_cs1_1_back')" v-if="sex === 'female' && cs === 1">
    <img :src="require('../../assets/img/options/components/hair/cs-1/female-hair-1.svg')">
  </div>
  
  <div @click="showHairStyle('#hair_female_style_cs1_2', '#hair_female_style_cs1_2_back')" v-if="sex === 'female' && cs === 1">
    <img :src="require('../../assets/img/options/components/hair/cs-1/female-hair-2.svg')">
  </div>
  
  <div @click="showHairStyle('#hair_female_style_cs1_3', '#hair_female_style_cs1_3_back')" v-if="sex === 'female' && cs === 1">
    <img :src="require('../../assets/img/options/components/hair/cs-1/female-hair-3.svg')">
  </div>

  <div @click="showHairStyle('#hair_female_style_cs1_4', '#hair_female_style_cs1_4_back')" v-if="sex === 'female' && cs === 1">
    <img :src="require('../../assets/img/options/components/hair/cs-1/female-hair-4.svg')">  
  </div>

  <div @click="showHairStyle('#hair_female_style_cs1_5', '#hair_female_style_cs1_5_back')" v-if="sex === 'female' && cs === 1">
    <img :src="require('../../assets/img/options/components/hair/cs-1/female-hair-5.svg')">  
  </div>

  <div @click="showHairStyle('#hair_female_style_cs1_6')" v-if="sex === 'female' && cs === 1">
    <img :src="require('../../assets/img/options/components/hair/cs-1/female-hair-6.svg')">  
  </div>

  <div @click="showHairStyle('#hair_female_style_cs1_7', '#hair_female_style_cs1_7_back')" v-if="sex === 'female' && cs === 1">
    <img :src="require('../../assets/img/options/components/hair/cs-1/female-hair-7.svg')">  
  </div>

  <div @click="showHairStyle('#hair_female_style_cs1_8', '#hair_female_style_cs1_8_back')" v-if="sex === 'female' && cs === 1">
    <img :src="require('../../assets/img/options/components/hair/cs-1/female-hair-8.svg')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs1_9', '#hair_female_style_cs1_9_back')" v-if="sex === 'female' && cs === 1">
    <img :src="require('../../assets/img/options/components/hair/cs-1/female-hair-9.svg')">     
  </div>

  <div @click="showHairStyle('#hair_female_style_cs1_10')" v-if="sex === 'female' && cs === 1">
    <img :src="require('../../assets/img/options/components/hair/cs-1/female-hair-10.svg')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs1_11', '#hair_female_style_cs1_11_back')" v-if="sex === 'female' && cs === 1">
    <img :src="require('../../assets/img/options/components/hair/cs-1/female-hair-11.svg')">   
  </div>


  <div @click="showHairStyle('#hair_female_style_cs1_12', '#hair_female_style_cs1_12_back')" v-if="sex === 'female' && cs === 1">
    <img :src="require('../../assets/img/options/components/hair/cs-1/female-hair-12.svg')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs1_13', '#hair_female_style_cs1_13_back')" v-if="sex === 'female' && cs === 1">
    <img :src="require('../../assets/img/options/components/hair/cs-1/female-hair-13.svg')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs1_14', '#hair_female_style_cs1_14_back')" v-if="sex === 'female' && cs === 1">
    <img :src="require('../../assets/img/options/components/hair/cs-1/female-hair-14.svg')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs1_15', '#hair_female_style_cs1_15_back')" v-if="sex === 'female' && cs === 1">
    <img :src="require('../../assets/img/options/components/hair/cs-1/female-hair-15.svg')">   
  </div>
  
  <!-- hair style cs-2 -->

  <div @click="showHairStyle('#hair_female_style_cs2_1', '#hair_female_style_cs2_1_back')" v-if="sex === 'female' && cs === 2">
    <img :src="require('../../assets/img/options/components/hair/cs-2/female-hair-1.svg')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs2_2', '#hair_female_style_cs2_2_back')" v-if="sex === 'female' && cs === 2">
    <img :src="require('../../assets/img/options/components/hair/cs-2/female-hair-2.svg')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs2_3', '#hair_female_style_cs2_3_back')" v-if="sex === 'female' && cs === 2">
    <img :src="require('../../assets/img/options/components/hair/cs-2/female-hair-3.svg')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs2_4', '#hair_female_style_cs2_4_back')" v-if="sex === 'female' && cs === 2">
    <img :src="require('../../assets/img/options/components/hair/cs-2/female-hair-4.svg')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs2_5', '#hair_female_style_cs2_5_back')" v-if="sex === 'female' && cs === 2">
    <img :src="require('../../assets/img/options/components/hair/cs-2/female-hair-5.svg')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs2_6', '#hair_female_style_cs2_6_back')" v-if="sex === 'female' && cs === 2">
    <img :src="require('../../assets/img/options/components/hair/cs-2/female-hair-6.svg')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs2_7', '#hair_female_style_cs2_7_back')" v-if="sex === 'female' && cs === 2">
    <img :src="require('../../assets/img/options/components/hair/cs-2/female-hair-7.svg')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs2_8')" v-if="sex === 'female' && cs === 2">
    <img :src="require('../../assets/img/options/components/hair/cs-2/female-hair-8.svg')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs2_9', '#hair_female_style_cs2_9_back')" v-if="sex === 'female' && cs === 2">
    <img :src="require('../../assets/img/options/components/hair/cs-2/female-hair-9.svg')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs2_10', '#hair_female_style_cs2_10_back')" v-if="sex === 'female' && cs === 2">
    <img :src="require('../../assets/img/options/components/hair/cs-2/female-hair-10.svg')">   
  </div>

  <div @click="showHairStyle('#hair_male_style_cs2_1', '#hair_male_style_cs2_1_back')" v-if="sex === 'male' && cs === 2">
    <img :src="require('../../assets/img/options/components/hair/cs-2/male-hair-1.svg')">   
  </div>

  <div @click="showHairStyle('#hair_male_style_cs2_2', '#hair_male_style_cs2_2_back')" v-if="sex === 'male' && cs === 2">
    <img :src="require('../../assets/img/options/components/hair/cs-2/male-hair-2.svg')">   
  </div>

  <div @click="showHairStyle('#hair_male_style_cs2_3', '#hair_male_style_cs2_3_back')" v-if="sex === 'male' && cs === 2">
    <img :src="require('../../assets/img/options/components/hair/cs-2/male-hair-3.svg')">   
  </div>

  <div @click="showHairStyle('#hair_male_style_cs2_4')" v-if="sex === 'male' && cs === 2">
    <img :src="require('../../assets/img/options/components/hair/cs-2/male-hair-4.svg')">   
  </div>
  
  <div @click="showHairStyle('#hair_male_style_cs2_5', '#hair_male_style_cs2_5_back')" v-if="sex === 'male' && cs === 2">
    <img :src="require('../../assets/img/options/components/hair/cs-2/male-hair-5.svg')">   
  </div>

  <div @click="showHairStyle('#hair_male_style_cs2_6')" v-if="sex === 'male' && cs === 2">
    <img :src="require('../../assets/img/options/components/hair/cs-2/male-hair-6.svg')">   
  </div>

  <div @click="showHairStyle('#hair_male_style_cs2_7')" v-if="sex === 'male' && cs === 2">
    <img :src="require('../../assets/img/options/components/hair/cs-2/male-hair-7.svg')">   
  </div>

  <div @click="showHairStyle('#hair_male_style_cs2_8')" v-if="sex === 'male' && cs === 2">
    <img :src="require('../../assets/img/options/components/hair/cs-2/male-hair-8.svg')">   
  </div>

  <div @click="showHairStyle('#hair_male_style_cs2_9')" v-if="sex === 'male' && cs === 2">
    <img :src="require('../../assets/img/options/components/hair/cs-2/male-hair-9.svg')">   
  </div>

  <div @click="showHairStyle('#hair_male_style_cs2_10')" v-if="sex === 'male' && cs === 2">
    <img :src="require('../../assets/img/options/components/hair/cs-2/male-hair-10.svg')">   
  </div>

  <!-- hair style cs-3 Male-->

  <div @click="showHairStyle('#hair_male_style_cs3_1')" v-if="sex === 'male' && cs === 3">
    <img :src="require('../../assets/img/options/components/hair/cs-3/male-hair-1.svg')">   
  </div>

  <div @click="showHairStyle('#hair_male_style_cs3_2')" v-if="sex === 'male' && cs === 3">
    <img :src="require('../../assets/img/options/components/hair/cs-3/male-hair-2.svg')">   
  </div>

  <div @click="showHairStyle('#hair_male_style_cs3_3')" v-if="sex === 'male' && cs === 3">
    <img :src="require('../../assets/img/options/components/hair/cs-3/male-hair-3.svg')">   
  </div>

  <div @click="showHairStyle('#hair_male_style_cs3_4')" v-if="sex === 'male' && cs === 3">
    <img :src="require('../../assets/img/options/components/hair/cs-3/male-hair-4.svg')">   
  </div>
  
  <div @click="showHairStyle('#hair_male_style_cs3_5')" v-if="sex === 'male' && cs === 3">
    <img :src="require('../../assets/img/options/components/hair/cs-3/male-hair-5.svg')">   
  </div>

  <div @click="showHairStyle('#hair_male_style_cs3_6')" v-if="sex === 'male' && cs === 3">
    <img :src="require('../../assets/img/options/components/hair/cs-3/male-hair-6.svg')">   
  </div>

  <div @click="showHairStyle('#hair_male_style_cs3_7')" v-if="sex === 'male' && cs === 3">
    <img :src="require('../../assets/img/options/components/hair/cs-3/male-hair-7.svg')">   
  </div>

  <div @click="showHairStyle('#hair_male_style_cs3_8')" v-if="sex === 'male' && cs === 3">
    <img :src="require('../../assets/img/options/components/hair/cs-3/male-hair-8.svg')">   
  </div>

  <div @click="showHairStyle('#hair_male_style_cs3_9')" v-if="sex === 'male' && cs === 3">
    <img :src="require('../../assets/img/options/components/hair/cs-3/male-hair-9.svg')">   
  </div>

  <div @click="showHairStyle('#hair_male_style_cs3_10')" v-if="sex === 'male' && cs === 3">
    <img :src="require('../../assets/img/options/components/hair/cs-3/male-hair-10.svg')">   
  </div>

  <div @click="showHairStyle('#hair_male_style_cs3_11')" v-if="sex === 'male' && cs === 3">
    <img :src="require('../../assets/img/options/components/hair/cs-3/male-hair-11.svg')">   
  </div>

  <div @click="showHairStyle('#hair_male_style_cs3_12')" v-if="sex === 'male' && cs === 3">
    <img :src="require('../../assets/img/options/components/hair/cs-3/male-hair-12.svg')">   
  </div>

  <div @click="showHairStyle('#hair_male_style_cs3_13')" v-if="sex === 'male' && cs === 3">
    <img :src="require('../../assets/img/options/components/hair/cs-3/male-hair-13.svg')">   
  </div>

  <div @click="showHairStyle('#hair_male_style_cs3_14')" v-if="sex === 'male' && cs === 3">
    <img :src="require('../../assets/img/options/components/hair/cs-3/male-hair-14.svg')">   
  </div>

  <div @click="showHairStyle('#hair_male_style_cs3_15')" v-if="sex === 'male' && cs === 3">
    <img :src="require('../../assets/img/options/components/hair/cs-3/male-hair-15.svg')">   
  </div>

  <!-- hair style cs-3 Female-->

  <div @click="showHairStyle('#hair_female_style_cs3_1','#hair_female_style_cs3_1_back')" v-if="sex === 'female' && cs === 3">
    <img :src="require('../../assets/img/options/components/hair/cs-3/female-hair-1.svg')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs3_2', '#hair_female_style_cs3_2_back')" v-if="sex === 'female' && cs === 3">
    <img :src="require('../../assets/img/options/components/hair/cs-3/female-hair-2.svg')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs3_3')" v-if="sex === 'female' && cs === 3">
    <img :src="require('../../assets/img/options/components/hair/cs-3/female-hair-3.svg')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs3_4','#hair_female_style_cs3_4_back')" v-if="sex === 'female' && cs === 3">
    <img :src="require('../../assets/img/options/components/hair/cs-3/female-hair-4.svg')">   
  </div>
  
  <div @click="showHairStyle('#hair_female_style_cs3_5', '#hair_female_style_cs3_5_back')" v-if="sex === 'female' && cs === 3">
    <img :src="require('../../assets/img/options/components/hair/cs-3/female-hair-5.svg')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs3_6')" v-if="sex === 'female' && cs === 3">
    <img :src="require('../../assets/img/options/components/hair/cs-3/female-hair-6.svg')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs3_7')" v-if="sex === 'female' && cs === 3">
    <img :src="require('../../assets/img/options/components/hair/cs-3/female-hair-7.svg')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs3_8', '#hair_female_style_cs3_8_back')" v-if="sex === 'female' && cs === 3">
    <img :src="require('../../assets/img/options/components/hair/cs-3/female-hair-8.svg')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs3_9', '#hair_female_style_cs3_9_back')" v-if="sex === 'female' && cs === 3">
    <img :src="require('../../assets/img/options/components/hair/cs-3/female-hair-9.svg')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs3_10', '#hair_female_style_cs3_10_back')" v-if="sex === 'female' && cs === 3">
    <img :src="require('../../assets/img/options/components/hair/cs-3/female-hair-10.svg')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs3_11', '#hair_female_style_cs3_11_back')" v-if="sex === 'female' && cs === 3">
    <img :src="require('../../assets/img/options/components/hair/cs-3/female-hair-11.svg')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs3_12', '#hair_female_style_cs3_12_back')" v-if="sex === 'female' && cs === 3">
    <img :src="require('../../assets/img/options/components/hair/cs-3/female-hair-12.svg')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs3_13', '#hair_female_style_cs3_13_back')" v-if="sex === 'female' && cs === 3">
    <img :src="require('../../assets/img/options/components/hair/cs-3/female-hair-13.svg')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs3_14')" v-if="sex === 'female' && cs === 3">
    <img :src="require('../../assets/img/options/components/hair/cs-3/female-hair-14.svg')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs3_15')" v-if="sex === 'female' && cs === 3">
    <img :src="require('../../assets/img/options/components/hair/cs-3/female-hair-15.svg')">   
  </div>

  <!-- hair style cs-4 Male-->

  <div @click="showHairStyle('#hair_male_style_cs4_1')" v-if="sex === 'male' && cs === 4">
    <img :src="require('../../assets/img/options/components/hair/cs-4/male-hair-1.png')">   
  </div>

  <div @click="showHairStyle('#hair_male_style_cs4_2')" v-if="sex === 'male' && cs === 4">
    <img :src="require('../../assets/img/options/components/hair/cs-4/male-hair-2.png')">   
  </div>

  <div @click="showHairStyle('#hair_male_style_cs4_3')" v-if="sex === 'male' && cs === 4">
    <img :src="require('../../assets/img/options/components/hair/cs-4/male-hair-3.png')">   
  </div>

  <div @click="showHairStyle('#hair_male_style_cs4_4')" v-if="sex === 'male' && cs === 4">
    <img :src="require('../../assets/img/options/components/hair/cs-4/male-hair-4.png')">   
  </div>
  
  <div @click="showHairStyle('#hair_male_style_cs4_5')" v-if="sex === 'male' && cs === 4">
    <img :src="require('../../assets/img/options/components/hair/cs-4/male-hair-5.png')">   
  </div>

  <div @click="showHairStyle('#hair_male_style_cs4_6')" v-if="sex === 'male' && cs === 4">
    <img :src="require('../../assets/img/options/components/hair/cs-4/male-hair-6.png')">   
  </div>

  <div @click="showHairStyle('#hair_male_style_cs4_7')" v-if="sex === 'male' && cs === 4">
    <img :src="require('../../assets/img/options/components/hair/cs-4/male-hair-7.png')">   
  </div>

  <div @click="showHairStyle('#hair_male_style_cs4_8', '#hair_male_style_cs4_8_back')" v-if="sex === 'male' && cs === 4">
    <img :src="require('../../assets/img/options/components/hair/cs-4/male-hair-8.png')">   
  </div>

  <div @click="showHairStyle('#hair_male_style_cs4_9')" v-if="sex === 'male' && cs === 4">
    <img :src="require('../../assets/img/options/components/hair/cs-4/male-hair-9.png')">   
  </div>

  <div @click="showHairStyle('#hair_male_style_cs4_10')" v-if="sex === 'male' && cs === 4">
    <img :src="require('../../assets/img/options/components/hair/cs-4/male-hair-10.png')">   
  </div>

  <div @click="showHairStyle('#hair_male_style_cs4_11')" v-if="sex === 'male' && cs === 4">
    <img :src="require('../../assets/img/options/components/hair/cs-4/male-hair-11.png')">   
  </div>

  <div @click="showHairStyle('#hair_male_style_cs4_12')" v-if="sex === 'male' && cs === 4">
    <img :src="require('../../assets/img/options/components/hair/cs-4/male-hair-12.png')">   
  </div>

  <div @click="showHairStyle('#hair_male_style_cs4_13')" v-if="sex === 'male' && cs === 4">
    <img :src="require('../../assets/img/options/components/hair/cs-4/male-hair-13.png')">   
  </div>

  <div @click="showHairStyle('#hair_male_style_cs4_14')" v-if="sex === 'male' && cs === 4">
    <img :src="require('../../assets/img/options/components/hair/cs-4/male-hair-14.png')">   
  </div>

  <div @click="showHairStyle('#hair_male_style_cs4_15')" v-if="sex === 'male' && cs === 4">
    <img :src="require('../../assets/img/options/components/hair/cs-4/male-hair-15.png')">   
  </div>

  <!-- hair style cs-4 Female-->

  <div @click="showHairStyle('#hair_female_style_cs4_1')" v-if="sex === 'female' && cs === 4">
    <img :src="require('../../assets/img/options/components/hair/cs-4/female-hair-1.png')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs4_2', '#hair_female_style_cs4_2_back')" v-if="sex === 'female' && cs === 4">
    <img :src="require('../../assets/img/options/components/hair/cs-4/female-hair-2.png')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs4_3')" v-if="sex === 'female' && cs === 4">
    <img :src="require('../../assets/img/options/components/hair/cs-4/female-hair-3.png')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs4_4', '#hair_female_style_cs4_4_back')" v-if="sex === 'female' && cs === 4">
    <img :src="require('../../assets/img/options/components/hair/cs-4/female-hair-4.png')">   
  </div>
  
  <div @click="showHairStyle('#hair_female_style_cs4_5', '#hair_female_style_cs4_5_back')" v-if="sex === 'female' && cs === 4">
    <img :src="require('../../assets/img/options/components/hair/cs-4/female-hair-5.png')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs4_6', '#hair_female_style_cs4_6_back')" v-if="sex === 'female' && cs === 4">
    <img :src="require('../../assets/img/options/components/hair/cs-4/female-hair-6.png')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs4_7', '#hair_female_style_cs4_7_back')" v-if="sex === 'female' && cs === 4">
    <img :src="require('../../assets/img/options/components/hair/cs-4/female-hair-7.png')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs4_8', '#hair_female_style_cs4_8_back')" v-if="sex === 'female' && cs === 4">
    <img :src="require('../../assets/img/options/components/hair/cs-4/female-hair-8.png')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs4_9')" v-if="sex === 'female' && cs === 4">
    <img :src="require('../../assets/img/options/components/hair/cs-4/female-hair-9.png')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs4_10', '#hair_female_style_cs4_10_back')" v-if="sex === 'female' && cs === 4">
    <img :src="require('../../assets/img/options/components/hair/cs-4/female-hair-10.png')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs4_11')" v-if="sex === 'female' && cs === 4">
    <img :src="require('../../assets/img/options/components/hair/cs-4/female-hair-11.png')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs4_12', '#hair_female_style_cs3_12_back')" v-if="sex === 'female' && cs === 4">
    <img :src="require('../../assets/img/options/components/hair/cs-4/female-hair-12.png')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs4_13')" v-if="sex === 'female' && cs === 4">
    <img :src="require('../../assets/img/options/components/hair/cs-4/female-hair-13.png')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs4_14', '#hair_female_style_cs4_14_back')" v-if="sex === 'female' && cs === 4">
    <img :src="require('../../assets/img/options/components/hair/cs-4/female-hair-14.png')">   
  </div>

  <div @click="showHairStyle('#hair_female_style_cs4_15')" v-if="sex === 'female' && cs === 4">
    <img :src="require('../../assets/img/options/components/hair/cs-4/female-hair-15.png')">   
  </div>
  

</div>

</template>

<script>
export default {
  name: "OptionsHair",
  // 获取当前性别
  computed: {
    sex () {
      return this.$store.state.sex
    },
    cs () {
      return this.$store.state.cs
    }
  },
  methods: {
    deleteOtherClass(){
      let c = document.getElementById('others').children, i;
      for (i = 0; i < c.length; i++) {
        c[i].classList.remove('show');
      }
      this.$store.state.selectDom2 = ''
    },
    deleteClass() {
      this.deleteOtherClass();
      let c = document.getElementById('hair').children, i;
      for (i = 0; i < c.length; i++) {
        c[i].classList.remove('show');
      }
      this.$store.state.selectDom = ''
    },
    showHairStyle(id, id_2) {
      this.deleteClass();
        document.querySelector(id).classList.add('show');
        this.$store.state.selectDom = id
        if(id_2) {
          document.querySelector(id_2).classList.add('show');
          this.$store.state.selectDom2 = id_2
        }
    }
  }
}
</script>

