<template>

<div id="options" :class="{'mobileOptions':isMobile}">
  
  <div style="padding:30px 20px">
    
    <h3 class="component-title">Avatar Style</h3>
    <div class="mn_sub">
      <div @click="changeCs(1)" class="styleshow" :class="{'selectStyle' : cs === 1}"><img :src="require('../assets/img/options/main/style1.png')"></div>
      <div @click="changeCs(2)" class="styleshow" :class="{'selectStyle' : cs === 2}"><img :src="require('../assets/img/options/main/style2.png')"></div>
      <div @click="changeCs(3)" class="styleshow" :class="{'selectStyle' : cs === 3}"><img :src="require('../assets/img/options/main/style3.png')"></div>
      <div @click="changeCs(4)" class="styleshow" :class="{'selectStyle' : cs === 4}"><img :src="require('../assets/img/options/main/style4.png')"></div>
    </div> 
    
    <h3 class="component-title">Face</h3>
    <div class="mn_sub">
      <div id="OptionsFaceskin">
        <OptionsFaceskin />
      </div>
    </div> 
    
    <h3 class="component-title">Hair</h3>
    <div class="mn_sub">
      <div id="OptionsFacialHair">
          <OptionsHair />
      </div>
    </div>
    
    <h3 class="component-title">Eyebrows</h3>
        <div class="mn_sub">
      <div id="OptionsEyebrows">
          <OptionsEyebrows />
      </div>
    </div>
    
    <h3 class="component-title">Eyes</h3>
    <div class="mn_sub">
      <div id="optionsEyes">
        <OptionsEyes />
      </div>
    </div>
    
    <h3 class="component-title">Mouths</h3>
      <div class="mn_sub">
      <div id="optionsMouths">
        <OptionsMouths />
      </div>
    </div>
    
    <h3 class="component-title">Clothes</h3>
    <div class="mn_sub">
      <div id="optionsClothes">
        <OptionsClothes />
      </div>
    </div>
    
    <h3 class="component-title" v-if="cs !== 1">Nose</h3>
    <div class="mn_sub" v-if="cs !== 1">
      <div id="OptionsNoses">
        <OptionsNoses />
      </div>
    </div>

    <h3 class="component-title" v-if="sex === 'male'">Beard</h3>
    <div class="mn_sub" v-if="sex === 'male'">
      <div id="OptionsFacialHair">
          <OptionsFacialHair />
      </div>
    </div>
  </div>  
</div>

</template>



<script>
import  OptionsFaceskin    from './options/faceskin'
import  OptionsHair        from './options/hair'
import  OptionsEyebrows    from './options/eyebrows'
import  OptionsEyes        from './options/eyes'
import  OptionsMouths      from './options/mouths'
import  OptionsFacialHair  from './options/facialhair'
import  OptionsClothes     from './options/clothes'
import  OptionsNoses       from './options/nose'
// import  OptionsEyeball     from './options/eyeball'
import  OptionsEar         from './options/ear'

// import  OptionsGlasses     from './options/glasses'
// import  OptionTattoos      from './options/tattoos'
// import  OptionsAccesories  from './options/accesories'


function initialState() {
  return {
    mainOpt: true,
    OptionsMouths: false,
    OptionsClothes: false,
    OptionsEyes: false,
    OptionsEyebrows: false,
    OptionsFaceskin: true,
    OptionsFacialHair: false,
    OptionsHair: false,
    OptionsNoses: false,
    OptionsEar: false,
    // OptionsEyeball: false,
    // OptionGlasses: false,
    // OptionTattoos: false,
    // OptionsAccesories: false,
    isMobile: navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    ),
    clothesImgSrc: require('../assets/img/options/main/1pt/clothes-male.svg'),
    hairImgSrc: require('../assets/img/options/main/1pt/hair-male.svg')
  }
}

export default {
  name: "Options",
  components: {
    OptionsFaceskin,
    OptionsFacialHair,
    OptionsHair,
    OptionsEyebrows,
    OptionsEyes,
    OptionsMouths,
    OptionsClothes,
    OptionsNoses,
    // OptionsEyeball,
    OptionsEar,
    // OptionsGlasses,
    // OptionTattoos,
    // OptionsAccesories
  },
  data() {
    return initialState();
  },
  computed: {
    sex () {
      return this.$store.state.sex
    },
    selectDom () {
      return this.$store.state.selectDom
    },
    cs () {
      return this.$store.state.cs
    }
  },
  watch: {
    //性别改变后执行
    sex(val){
      this.removeSelectOption()
      this.clothesImgSrc = require('../assets/img/options/main/1pt/clothes-' + this.sex + '.svg')
      this.hairImgSrc = require('../assets/img/options/main/1pt/hair-' + this.sex + '.svg')
    },
    selectDom(val){ //option添加选中效果
      this.$nextTick(()=>{ //dom渲染完后在执行
        this.removeSelectOption()
        //为当前选中组件添加选中效果
        const newAttrs = val.replace('#', '').split('_')   
        let newDom = document.getElementsByClassName('sub_components')
        newAttrs.forEach((item,index) => {
          if (index === 0) { //选项组名
            if (item === 'faceskin') newDom = newDom[0]
            else if (item === 'hair') newDom = newDom[1]
            else if (item === 'eb') newDom = newDom[2]
            else if (item === 'e') newDom = newDom[3]
            else if (item === 'm') newDom = newDom[4]
            else if (item === 'c') newDom = newDom[5]
            else if (item === 'noses') newDom = newDom[6]
            else if (item === 'facialhair') newDom = newDom[7]
          }
          if(/^\d+$/.test(item)){ // 整数项
            // if (oldAttrs[0] === 'c'){
            //   const imgs = newDom.getElementsByTagName('img')
            //   for (let i=0; i<imgs.length; i++){
            //     const srcArr = imgs[i].src.split('/')
            //     let name = srcArr[srcArr.length - 1]
            //     name = name.split('.')[0]
            //     name.split('-').forEach(n => {
            //       if(/^\d+$/.test(n) && n == item){
            //         console.log(imgs[i], 'result')
            //       }
            //     })
            //     console.log(name)
            //   }
            // }
            const optionDivs = newDom.children
            const newSelect = optionDivs[parseInt(item) - 1]
            newSelect.classList.add('selectOption')
          }
        })
      })
    }
  },
  methods: {
    resetMenu() {
      Object.assign(this.$data, initialState())
      this.clothesImgSrc = require('../assets/img/options/main/1pt/clothes-' + this.sex + '.svg')
      this.hairImgSrc = require('../assets/img/options/main/1pt/hair-' + this.sex + '.svg')
    },
    reset() {
      this.OptionsMouths = false
      this.OptionsClothes = false
      this.OptionsEyes = false
      this.OptionsEyebrows = false
      this.OptionsFaceskin = false
      this.OptionsFacialHair = false
      this.OptionsHair = false
      this.OptionsEar = false
      this.OptionsEyeball = false
      this.OptionsNoses = false
      // this.OptionGlasses = false
      // this.OptionTattoos = false
      // this.OptionsAccesories = false
    },
    changeCs(cs) {
      this.removeSelectOption()
      this.$root.$refs.App.changeCs(cs);
    },
    removeSelectOption() {
      //移除所有选中效果
      let allDiv = document.getElementsByTagName('div')
      for (let i = 0; i < allDiv.length; i++) {
        allDiv[i].classList.remove('selectOption')
      }
    }
  },
  created() {
    this.$root.$refs.Options = this;
  }
}
</script>

<style scoped>

.box-card{
  background-color: transparent;
  border: none;
}

.component-title{
  color: #ededed;
  font-size: 18px;
}

</style>