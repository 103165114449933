<template>
  <div id="app">   
    <div id="main" style="width:100%; height:100%; background-color: #14161a;">
      <el-container style="height:100%">
        <el-container>
          <el-header class="head-style"><img src="./assets/img/avalogo.svg"></el-header>
          <div id='stars'></div>
          <div class="container gradientbg" style="display: flex;flex: 1;flex-direction: column;align-items: center;justify-content: center;padding: 2rem 0;">
            <div id="avatar">
              <!-- <OptionsColor /> -->
              <Others />
              <SkinColor />
              <Faceskin />
              <Hair />
              <Eyeball />
              <Eyebrows />
              <Eyes />
              <Mounths />
              <Facialhair />
              <Clothes />
              <Noses />
              <Ear />
              <!-- <Glasses /> -->
              <!-- <Accesories /> -->
              <!-- <Tattoos /> -->
            </div>          
             <Tool />
          </div>
          <!-- 移动端展开选项的时候隐藏 -->
          <el-footer class="text-center" v-if="!(isMobile&&showOption)"> 
            <el-link href="https://avamake.com/how-to-use-avamaker.html" target="_blank">HOW TO</el-link> | <el-link href="https://avamake.com/best-avatar-makers.html" target="_blank">TOP 12</el-link> | <el-link href="https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/111714817145550825954899637141200955082120246901527949641584258617096475246593" ref="nofollow" target="_blank">NFT SAMPLE</el-link> | <el-link href="https://www.accuratereviews.com/top-five-finest-avatar-makers/" target="_blank">ACCURATEREVIEWS</el-link> | <el-link href="https://avamake.com/privacy.html" rel="nofollow" target="_blank">PRIVACY POLICY</el-link> | <el-link href="https://avamake.com/terms.html" ref="nofollow" target="_blank">TERMS OF USE</el-link>
          </el-footer>
        </el-container>
        <el-aside width="25rem" style="background-color: #303133; height: 100%;" v-if="showOption" :class="{'mobileAside':isMobile}">
          <Options />           
        </el-aside>
      </el-container>
      <div class="arrowBlock" @click="showOption = !showOption" :class="{'arrowBlock2':!showOption, 'mobileBlock': isMobile}">
        <i class="el-icon-caret-right" v-if="showOption"></i>
        <i class="el-icon-caret-left" v-else></i>
      </div>
    </div>  
  </div>
</template>

<script>
// import avatar component
import Clothes     from './components/layout/clothes'
import Eyebrows    from './components/layout/eyebrows'
import Eyes        from './components/layout/eyes'
import Facialhair  from './components/layout/facialhair'
import Faceskin    from './components/layout/faceskin'
import Hair        from './components/layout/hair'
import Mounths     from './components/layout/mouths'
import SkinColor   from './components/layout/SkinColor'
import Ear         from './components/layout/ear'
import Eyeball     from './components/layout/eyeball'
import Noses       from './components/layout/nose'
import Others       from './components/layout/others'
// import Glasses     from './components/layout/glasses'
// import Accesories  from './components/layout/accesories'
// import Tattoos     from './components/layout/tattoos'

// import options color
import OptionsColor from './components/optionsColor'

// import Tool
import Tool from './components/tool'
import Options from './components/options'

export default {
  name: 'App',
  metaInfo: {
    title: 'Ava Maker - Free Avatar Maker Online',
    htmlAttrs: {
      lang: 'en'
    },

    meta: [
      // Meta Seo
      { vmid: 'robots', name: 'robots', content: 'index, follow' },
      { vmid: 'description', name: 'description', content: 'Want a stunning avatar or make an NFT? Try AvaMaker, a free avatar & NFT maker online. Trendy avatar styles & numerous elements are ready for use! ' },
      { vmid: 'theme-color', name: 'theme-color', content: '#0077FF' },

      // Open Graph
      { vmid: 'og:locale', property: 'og:locale', content: 'en_US' },
      { vmid: 'og:site_name', property: 'og:site_name', content: 'Avatar Maker' },
      { vmid: 'og:type', property: 'og:type', content: 'website' },
      // { vmid: 'og:image', property: 'og:image', content: 'https://github.com/favrora/Avatar-Maker/blob/master/src/assets/img/preview.jpg' },

      // Twitter
      { vmid: 'twitter:card', property: 'twitter:card', content: 'summary_large_image' },
      { vmid: 'twitter:creator', property: 'twitter:creator', content: 'favrora_com' },
    ],
  },
  data () {
    return {
      showOption: true,
      isMobile: navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    }
  },
  components: {
    Clothes,
    Eyebrows,
    Eyes,
    Facialhair,
    Faceskin,
    Hair,
    Mounths,
    SkinColor,
    Tool,
    Options,
    OptionsColor,
    Ear,
    Eyeball,
    Noses,
    Others,
    // Accesories,
    // Tattoos,
    // Glasses,
  },

  methods: {
    resetAvatar() {
      // if (this.$store.state.cs === 1) {
      //   this.$root.$refs.Eyebrows.picker('#eb_style_1')
      //   this.$root.$refs.Eyes.picker('#e_style_1')
      //   this.$root.$refs.Faceskin.picker('#faceskin_style_1');
      //   this.$root.$refs.Mouths.picker('#m_style_1')
      //   this.$root.$refs.Noses.picker('#noses_style_1');
      //   if (this.$store.state.sex === 'female') {
      //     this.$root.$refs.Clothes.picker('#c_female_style_1')
      //     this.$root.$refs.Hair.picker('#hair_female_style_1')
      //   }
      //   else {
      //     this.$root.$refs.Clothes.picker('#c_style_1')
      //     this.$root.$refs.Hair.picker('#hair_style_1')
      //   }
      //   this.$store.state.selectDom = '#faceskin_style_1'
      // } else {
      this.$root.$refs.Clothes.picker('#c_' + this.$store.state.sex + '_style_cs' + this.$store.state.cs +'_1')
      this.$root.$refs.Eyebrows.picker('#eb_' + this.$store.state.sex + '_style_cs' + this.$store.state.cs +'_1')
      this.$root.$refs.Eyes.picker('#e_' + this.$store.state.sex + '_style_cs' + this.$store.state.cs +'_1')
      this.$root.$refs.Faceskin.picker('#faceskin_style_cs' + this.$store.state.cs + '_' + this.$store.state.sex + '_1')
      this.$root.$refs.Hair.picker('#hair_' + this.$store.state.sex + '_style_cs' + this.$store.state.cs +'_1')
      this.$root.$refs.Mouths.picker('#m_' + this.$store.state.sex + '_style_cs' + this.$store.state.cs +'_1')
      this.$root.$refs.Noses.picker('#noses_' + this.$store.state.sex + '_style_cs' + this.$store.state.cs +'_1')
      this.$store.state.selectDom = '#faceskin_style_cs' + this.$store.state.cs + '_' + this.$store.state.sex + '_1'
      // }
      this.$root.$refs.FacialHair.picker('#f_none');
      if (this.$store.state.cs !== 1) this.$root.$refs.SkinColor.picker();
      else this.$root.$refs.SkinColor.picker('#body_style_cs' + this.$store.state.cs + '_' + this.$store.state.sex + '_1')
      this.$root.$refs.Others.picker('#hair_' + this.$store.state.sex + '_style_cs' + this.$store.state.cs + '_1_back')
      // this.$root.$refs.Ear.picker('#ear_style_1');
      // this.$root.$refs.Eyeball.picker('#eyeball_none');
      // this.$root.$refs.Glasses.picker('#g_none');
      // this.$root.$refs.Accesories.picker('#g_none');
      // this.$root.$refs.Tattoos.picker('#t_none');
    },
    randomAvatar() {
      this.$root.$refs.Clothes.picker();
      this.$root.$refs.Eyebrows.picker();
      this.$root.$refs.Eyes.picker();
      this.$root.$refs.FacialHair.picker();
      this.$root.$refs.Faceskin.picker();
      this.$root.$refs.Hair.picker();
      this.$root.$refs.Mouths.picker();
      this.$root.$refs.SkinColor.picker();
      // this.$root.$refs.Ear.picker();
      // this.$root.$refs.Eyeball.picker();
      this.$root.$refs.Noses.picker();
      // this.$root.$refs.Accesories.picker();
      // this.$root.$refs.Tattoos.picker();
      // this.$root.$refs.Glasses.picker();
    },
    changeCs (cs) {
      this.$store.state.cs = cs
      this.resetAvatar()
    }
  },

  mounted() {
    window.addEventListener('load', () => {
      this.resetAvatar();
    })
    if (this.isMobile) this.showOption = false
  },

  created() {
    this.$root.$refs.App = this;
  }
}
</script>

// import scss
<style lang="scss">
@import "./assets/sass/variables";
@import "./assets/sass/responsive";
@import "./assets/sass/avatar";
@import "./assets/sass/tool";
@import "./assets/sass/save_image";
@import "./assets/sass/options_color";

.el-aside{
  scrollbar-color: #d4aa70 #e4e4e4;
  scrollbar-width: thin;
}
  .el-aside::-webkit-scrollbar {
    width: 20px;
  }

  .el-aside::-webkit-scrollbar-track {
    background-color: #414141;
  }

  .el-aside::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border: 5px solid transparent;
    background-clip: content-box;
    background-color: #6d61a8;
  }

.el-header, .el-footer {
    background-color: #22262e;
    color: #848484;
    font-size: 14px;
    line-height: 60px;
  }
  .el-header img{
    vertical-align: middle;
    height:40px; 
  }
</style>
