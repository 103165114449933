<template>

<div class="sub_components">

  <div @click="showEarStyle('#ear_style_1')">
    <svg viewBox="0 0 270 270">
     <g id="ear_style_1">
        <path class="cls-3" d="M148,140.85c-4.88-15.15-16.44-11.64-17.53-11.28-1.13.13-13.08,1.91-11,17.69s11.29,24.47,19.93,22C148.25,167.8,152.89,156.08,148,140.85Z"/>
    <path class="cls-3" d="M245.72,140.85c4.89-15.15,16.45-11.64,17.53-11.28,1.14.13,13.09,1.91,11,17.69s-11.29,24.47-19.92,22C245.46,167.8,240.81,156.08,245.72,140.85Z"/>
    </g>
    </svg>
  </div>
</div>

</template>

<script>
export default {
  name: "OptionsEar",
  methods: {
    deleteClass() {
      let c = document.getElementById('ear').children, i;
      for (i = 0; i < c.length; i++) {
        c[i].classList.remove('show');
      }
      this.$store.state.selectDom = ''
    },
    showEarStyle(id) {
      if(document.querySelector(id).classList.contains('show')) {
        this.deleteClass();
      } else {
        this.deleteClass();
        document.querySelector(id).classList.add('show');
        this.$store.state.selectDom = id
      }
    },    
  }
}
</script>