<template>

<div class="sub_components">
  <!-- eye style cs-1-->
  <div v-for="index in 15" :key="index" @click="showEyes('#e_female_style_cs1_' + index)" v-if="sex === 'female' && cs === 1">
    <img :src="require('../../assets/img/options/components/eye/cs-1/female-eye-' + index + '.svg')">
  </div>

  <div v-for="index in 15" :key="index" @click="showEyes('#e_male_style_cs1_' + index)"  v-if="sex === 'male' && cs === 1">
    <img :src="require('../../assets/img/options/components/eye/cs-1/male-eye-' +index + '.svg')">
  </div>

  <!-- eye style cs-2-->
  <div v-for="index in 10" :key="index" @click="showEyes('#e_female_style_cs2_' + index)" v-if="sex === 'female' && cs === 2">
    <img :src="require('../../assets/img/options/components/eye/cs-2/female-eye-' + index + '.svg')">
  </div>

  <div v-for="index in 10" :key="index" @click="showEyes('#e_male_style_cs2_' + index)"  v-if="sex === 'male' && cs === 2">
    <img :src="require('../../assets/img/options/components/eye/cs-2/male-eye-' +index + '.svg')">
  </div>

  <!-- eye style cs-3-->
  <div v-for="index in 15" :key="index" @click="showEyes('#e_female_style_cs3_' + index)" v-if="sex === 'female' && cs === 3">
    <img :src="require('../../assets/img/options/components/eye/cs-3/female-eye-' + index + '.svg')">
  </div>

  <div v-for="index in 15" :key="index" @click="showEyes('#e_male_style_cs3_' + index)"  v-if="sex === 'male' && cs === 3">
    <img :src="require('../../assets/img/options/components/eye/cs-3/male-eye-' +index + '.svg')">
  </div>

  <!-- eye style cs-4-->
  <div v-for="index in 15" :key="index" @click="showEyes('#e_female_style_cs4_' + index)" v-if="sex === 'female' && cs === 4">
    <img :src="require('../../assets/img/options/components/eye/cs-4/female-eye-' + index + '.svg')">
  </div>

  <div v-for="index in 15" :key="index" @click="showEyes('#e_male_style_cs4_' + index)"  v-if="sex === 'male' && cs === 4">
    <img :src="require('../../assets/img/options/components/eye/cs-4/male-eye-' +index + '.svg')">
  </div>

</div>

</template>

<script>
export default {
  name: "OptionsEyes",
  computed: {
    sex () {
      return this.$store.state.sex
    },
    cs () {
      return this.$store.state.cs
    }
  },
  methods: {
    deleteClass() {
      let c = document.getElementById('eyes').children, i;
      for (i = 0; i < c.length; i++) {
        c[i].classList.remove('show');
      }
      this.$store.state.selectDom = ''
    },
     showEyes(id) {
      this.deleteClass();
      document.querySelector(id).classList.add('show');
      this.$store.state.selectDom = id
    }
  }
}


</script>


