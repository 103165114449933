<template>
  <svg id="ear" width="380px" height="380px" viewBox="0 0 380 380" style="z-index: 10">
    <g id="ear_style_1">
        <path fill="#e08880" d="M148,140.85c-4.88-15.15-16.44-11.64-17.53-11.28-1.13.13-13.08,1.91-11,17.69s11.29,24.47,19.93,22C148.25,167.8,152.89,156.08,148,140.85Z"/>
        <path fill="#e08880" d="M245.72,140.85c4.89-15.15,16.45-11.64,17.53-11.28,1.14.13,13.09,1.91,11,17.69s-11.29,24.47-19.92,22C245.46,167.8,240.81,156.08,245.72,140.85Z"/>
    </g>
    <g id="ear_none"></g>
  </svg>
</template>


<script>
export default {
  name: "Ear",
  data() {
    return {
      list: [
        '#ear_none'
      ]
    }
  },

  methods: {
    picker(option = false){
      this.hide_all();
      option
        ? document.querySelector(option).classList.add('show')
        : document.querySelector(this.random_option()).classList.add('show');
    },
    random_option(){
      let list = []
      if (this.$store.state.cs === 1) list = ['#ear_style_1']
      else list = ['#ear_none']
      var chosen = Math.floor(Math.random() * list.length);
      return list[chosen];
    },
    hide_all(){
      for (let i = 0; i < this.list.length; i++){
        document.querySelector(this.list[i]).classList.remove('show');
      }
    }
  },

  created() {
    this.$root.$refs.Ear = this;
  }
}
</script>

<style>
  @import url('../../assets/css/custom.css');
</style>